* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

@font-face {
	font-family: 'Open Sans R';
	src: url('assets/fonts/OpenSans-Regular.ttf');
}

@font-face {
	font-family: 'Open Sans SB';
	src: url('assets/fonts/OpenSans-SemiBold.ttf');
}

@font-face {
	font-family: 'Open Sans B';
	src: url('assets/fonts/OpenSans-Bold.ttf');
}

@font-face {
	font-family: 'Moderat R';
	src: url('assets/fonts/Moderat-Regular.otf');
}

@font-face {
	font-family: 'Moderat M';
	src: url('assets/fonts/Moderat-Medium.otf');
}

@font-face {
	font-family: 'Moderat B';
	src: url('assets/fonts/Moderat-Bold.otf');
}

@font-face {
	font-family: 'Moderat EB';
	src: url('assets/fonts/Moderat-Extended-Bold.otf');
}

html,
body {
	font-family: 'Open Sans R';
}

a {
	text-decoration: none;
}

a:focus {
	outline: none;
}

button {
	outline: none;
	border: none;
}

.container-1280 {
	max-width: 1200px;
	margin: auto;
}

@media screen and (max-width: 1300px) {
	.container-1280 {
		width: 90%;
		margin: auto;
	}
}

.p-relative {
	position: relative;
}

.btn-white {
	font-family: 'Open Sans SB';
	display: inline-block;
	background-color: white;
	color: #0b2959;
	border-radius: 20px;
	padding: 12px 30px;
}

.btn-blue {
	font-family: 'Open Sans SB';
	display: inline-block;
	background-color: #205080;
	color: #fff;
	border-radius: 20px;
	padding: 12px 30px;
}

.btn-blue:hover {
	background-color: #fff;
	color: #205080;
	box-shadow: 0px 3px 6px #00000029;
}


/* MENÚ MOBILE */

.dp-none {
	display: none;
}

.header-mobile-img {
	width: 80px;
	position: absolute;
	top: 20px;
	left: 10%;
	z-index: 1;
}

.menu-toggle {
	width: 30px;
	display: none;
}

.menu-toggle span {
	display: block;
	width: 100%;
	height: 2px;
	background-color: white;
	margin-bottom: 6px;
	transform-origin: 0px 100%;
	transition: all 400ms;
}

.menu-toggle span:last-of-type {
	margin-bottom: 0;
}

.activeline1 {
	background-color: #0b2959 !important;
	transform: rotate(45deg) translate(-2px, 1px);
}

.activeline3 {
	background-color: #0b2959 !important;
	transform: rotate(-45deg) translate(-4px, 2px);
}

.activeline2 {
	opacity: 0;
}

.logo-header-mobile {
	width: 85px;
	margin-bottom: 30px;
}

.logo-menu-mobile {
	display: none;
}

.menu-mobile {
	display: none;
}

.menu-mobile-container {
	display: flex;
	position: fixed;
	align-items: center;
	padding: 20px 10%;
	justify-content: space-between;
	width: 100%;
	z-index: 99;
}

.btn.menu-mobile-btn:hover,
.btn.menu-mobile-btn:focus {
	border: none;
}

.menu-mobile ul {
	padding: 0;
}

.menu-mobile ul li {
	list-style: none;
	margin-bottom: 10px;
}

.menu-mobile ul li a {
	text-decoration: none;
	color: #0b2959;
}

@media screen and (max-width: 992px) {
	.logo-menu-mobile {
		display: block;
		height: 35px;
	}
}

@media screen and (max-width: 360px) {
	.menu-mobile-btn {
		left: 75%;
	}
}

.login-toggle-mobile {
	width: 200px;
	border-radius: 20px;
	box-shadow: 0px 3px 6px #00000029;
	background-color: white;
	overflow: hidden;
	position: relative;
	text-align: center;
}

.toggle-login {
	width: 100px;
	padding: 10px 15px;
	cursor: pointer;
	background-color: transparent;
	border: 0;
	outline: none;
	position: relative;
	top: 0;
	right: 50px;
	text-align: center;
	color: #205080;
}

.toggle-login.active {
	color: white;
	background-color: #205080;
	border-radius: 20px;
	bottom: 0;
	right: 0;
	position: absolute;
}

.btn-login {
	width: auto;
	padding: 10px 20px;
	padding-right: 40px;
	cursor: pointer;
	outline: none;
	text-align: center;
	border-radius: 20px;
	background-color: #fff;
	color: #205080;
	border: 0;
	box-shadow: 0px 3px 6px #00000029;
}

.btn-login:hover,
.btn-login:active,
.btn-login:focus {
	background-color: #d7dce4;
	color: #205080;
}

.btn-signup {
	width: auto;
	padding: 10px 30px;
	cursor: pointer;
	outline: none;
	text-align: center;
	border-radius: 20px !important;
	color: #fff;
	background-color: #205080;
	border: 0;
	position: absolute;
	left: -20px;
	z-index: 2;
	box-shadow: 0px 3px 6px #00000029;
}

.btn-signup:hover,
.btn-signup:active,
.btn-signup:focus {
	color: #d7dce4;
	background-color: #205080;
}

.rrss-header-mobile-container {
	margin-top: 30px;
}

.rrss-header-mobile-container p {
	color: #0b2959;
	font-size: 14px;
}

.rrss-header-mobile {
	display: flex;
	column-gap: 10px;
}

.rrss-header-mobile img {
	height: 32px;
}

@media screen and (max-width:420px) {
	.menu-toggle {
		left: 85%;
	}

	.menu-mobile.active {
		max-width: 90vw;
	}
}

@media screen and (max-width: 992px) {
	.menu-toggle {
		display: block;
	}

	.header-mobile-img {
		display: block;
	}

	.menu-mobile {
		display: block;
		padding: 0 25px;
	}
}

.offcanvas-header {
	justify-content: flex-end;
}

.owl-carousel .owl-dots {
	margin: auto;
	text-align: center;
}

.owl-carousel .owl-dots button {
	border: 1px solid white;
	width: 18px;
	height: 18px;
	border-radius: 50%;
	margin: 0 10px;
}

.owl-carousel .owl-dots button.owl-dot.active {
	background-color: white;
}


/* MENÚ DESKTOP */

header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 99;
	padding: 30px 0;
}

.header-container {
	margin: auto;
	justify-content: space-between;
	display: flex;
	align-items: center;
	position: relative;
}

.header-links-container ul {
	padding: 0;
	margin: 0;
}

.header-links-container ul li {
	list-style: none;
	display: inline;
	margin: 0 30px;
}

.header-links-container ul li a {
	color: white;
	font-weight: 700;
	text-decoration: none;
}

.header-links-container ul li a:hover,
.header-links-container ul li a:active,
.header-links-container ul li a:focus {
	color: #75BAFF;
	text-decoration: none;
}

.header-links-container .active {
	color: #75BAFF;
	text-decoration: none;
}

.header-links-container.header-links-individuals ul li a:hover,
.header-links-container.header-links-individuals ul li a:active,
.header-links-container.header-links-individuals ul li a:focus {
	color: #4CBD43;
	text-decoration: none;
}

.header-links-container.header-links-individuals .active {
	color: #4CBD43;
	text-decoration: none;
}

.header-links-container.header-links-advance ul li a:hover,
.header-links-container.header-links-advance ul li a:active,
.header-links-container.header-links-advance ul li a:focus {
	color: #A6AFBD;
	text-decoration: none;
}

.header-links-container.header-links-advance .active {
	color: #A6AFBD;
	text-decoration: none;
}

.header-links-container.header-links-corporate ul li a:hover,
.header-links-container.header-links-corporate ul li a:active,
.header-links-container.header-links-corporate ul li a:focus {
	color: #C744F5;
	text-decoration: none;
}

.header-links-container.header-links-corporate .active {
	color: #C744F5;
	text-decoration: none;
}

.header-sticky {
	background-color: rgba(9, 34, 73, .9);
}

.header-sticky-mobile {
	position: fixed;
	display: flex;
	background-color: rgba(9, 34, 73, .9);
}

@media screen and (max-width: 1200px) {
	.header-links-container ul li {
		margin: 0 15px;
	}
}

@media screen and (max-width: 991.99px) {
	header {
		display: none;
	}
}


/* FOOTER */

.footer {
	background-color: #BDC8D8;
	padding-top: 5%;
	padding-bottom: 3%;
	border-top: 2px solid #2C507C;
}

.footer-container {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	padding-bottom: 5%;
}

.footer-logo-container {
	display: flex;
	flex-direction: column;
	row-gap: 20px;
}

.footer-link-container {
	width: 20%;
}

.footer-link-title {
	font-family: 'Moderat B';
	font-size: 24px;
	color: #205080;
}

.footer-link {
	display: block;
	color: #205080;
	font-size: 16px;
	margin-bottom: 10px;
}

.footer-link:hover {
	font-family: 'Open Sans B';
	text-decoration: underline;
	color: #205080;
}

.copy-container {
	display: flex;
	justify-content: space-between;
	padding-top: 20px;
	border-top: 1px solid #ffffff;
}

.copy-container p {
	color: #205080;
	font-size: 12px;
	margin-bottom: 0px;
}

.copy-logo-container {
	display: flex;
	column-gap: 15px;
	margin-right: 100px;
}

.footer-social-container img {
	height: 32px;
	display: inline-block;
	margin-right: 5px;
}

@media screen and (max-width: 576px) {
	.footer-container {
		padding: 30px 0 0 0;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
	}

	.footer-container div {
		width: 100%;
		margin: auto;
		margin-bottom: 15px;
		text-align: center;
	}

	.footer-logo-container {
		margin: auto;
		text-align: center;
	}

	.footer-social-container {
		display: flex;
		align-items: center;
		justify-content: center;
		column-gap: 15px;
	}

	.logo-footer-bitex {
		width: 100px;
		margin: auto;
	}

	.footer-link-title {
		margin-bottom: 10px;
	}

	.copy-container {
		flex-wrap: wrap;
		justify-content: center;
		row-gap: 10px;
		padding-bottom: 30px;
	}

	.copy-logo-container {
		width: 100%;
		justify-content: center;
		text-align: center;
	}
}


/* HOME: S1 */

.home-s1 {
	background: url('assets/images/bg/home-s1.jpg'), #ffffff;
	background-position: bottom;
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
	margin-bottom: 40px;
}

.homes1-container {
	max-width: 1280px;
	margin: auto;
	padding: 25vh 0;
}

.homes1-title-container {
	text-align: center;
}

.homes1-title {
	font-family: 'Moderat B';
	color: white;
	font-size: 60px;
	line-height: 120%;
	margin-bottom: 5px;
}

.homes1-subtitle {
	color: white;
	font-size: 25px;
	margin-bottom: 50px;
}

.homes1-text {
	color: white;
	font-family: 'Open Sans B';
	text-align: center;
	font-size: 25px;
}

.homes1-icon-container {
	margin-top: 20px;
	display: flex;
	column-gap: 30px;
	justify-content: center;
	padding: 20px;
}

.homes1-icon {
	width: 200px;
	height: 200px;
	background: url('assets/images/bg/homes1-icon.svg');
	background-size: cover;
	background-repeat: no-repeat;
	border-radius: 50%;
	padding: 30px;
	display: flex;
	row-gap: 7px;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
}

.homes1-icon p {
	text-align: center;
	line-height: 130%;
	width: 80%;
	color: white;
	font-size: 15px;
	margin-bottom: 0px;
	font-family: 'Open Sans SB';
}

.homes1-icon img {
	width: 95px;
}

@media screen and (max-width: 992px) {
	.homes1-icon-container {
		justify-content: space-between;
		column-gap: 15px;
	}

	.homes1-icon {
		width: 210px;
		height: 210px;
		padding: 15px;
	}

	.homes1-icon img {
		width: 95px;
		margin-top: 10px;
	}
}

@media screen and (max-width: 768px) {
	.homes1-icon-container {
		flex-wrap: wrap;
		align-items: center;
		column-gap: 100%;
		justify-content: center;
		row-gap: 20px;
	}

	.homes1-container {
		padding: 15% 0;
	}

	.homes1-title {
		font-size: 45px;
	}

	.homes1-subtitle {
		font-size: 18px;
	}

	.homes1-text {
		font-size: 21px;
	}
}

@media screen and (max-width: 576px) {
	.homes1-container {
		padding: 25% 0;
	}
}


/* HOME: S2 */

.home-s2 {
	margin-bottom: 50px;
}

.homes2-title {
	font-family: 'Moderat B';
	font-size: 40px;
	color: #205080;
	text-align: center;
	line-height: 130%;
	width: 20%;
	margin: auto;
	margin-bottom: 100px;
}

.homes2-item-container {
	position: relative;
	height: 445px;
	width: 250px;
	padding: 80px 30px 40px 30px;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.homes2-item-img {
	position: absolute;
	top: -25px;
	left: 35%;
}

.homes2-item-img img {
	height: 75px;
}

.homes2-item-container p {
	color: #205080;
	font-size: 12px;
	line-height: 140%;
}

.homes2-link {
	text-align: center;
	font-family: 'Open Sans B';
	color: #0083FF;
	font-size: 14px;
}

.homes2-link:hover {
	color: #8DC350;
}

.home-s2 {
	overflow: hidden;
}

.carousel-homes2.owl-carousel .owl-stage-outer {
	overflow: visible;
	max-width: 100vw;
}

.carousel-homes2.owl-carousel .owl-nav {
	position: relative;
	display: flex;
	justify-content: center;
	gap: 5px;
	margin-top: 30px;
}

.carousel-homes2.owl-carousel .owl-nav button {
	color: #8C9BB3;
	background-color: #d7dce4;
	height: 50px;
	width: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
}

.carousel-homes2.owl-carousel .owl-nav button span {
	font-size: 45px;
	margin-bottom: 8px;
}

.carousel-homes2.owl-carousel .owl-nav button.owl-prev {
	left: 70%;
}

.carousel-homes2.owl-carousel .owl-nav button.owl-next {
	left: 75%;
}

.carousel-homes2.owl-carousel .owl-nav button:hover {
	background-color: #205080;
	color: white;
}

@media screen and (max-width: 1200px) {
	.homes2-title {
		width: 40%;
	}

	.carousel-homes2.owl-carousel .owl-nav button {
		top: -680px;
	}

	.carousel-homes2.owl-carousel .owl-nav button.owl-prev {
		left: 66%;
	}

	.carousel-homes2.owl-carousel .owl-nav button.owl-next {
		left: 72%;
	}
}

@media screen and (max-width: 992px) {
	.carousel-homes2.owl-carousel .owl-nav button.owl-prev {
		left: 72%;
	}

	.carousel-homes2.owl-carousel .owl-nav button.owl-next {
		left: 80%;
	}
}

@media screen and (max-width: 768px) {
	.individuals .home-s5.form-container .form {
		margin-top: 100px;
	}

	.homes2-title {
		margin-bottom: 120px;
		font-size: 28px;
	}

	.carousel-homes2.owl-carousel .owl-nav button {
		top: -545px;
		height: 40px;
		width: 40px;
	}

	.carousel-homes2.owl-carousel .owl-nav button span {
		font-size: 35px;
	}

	.carousel-homes2.owl-carousel .owl-nav button.owl-prev {
		left: 40%;
	}

	.carousel-homes2.owl-carousel .owl-nav button.owl-next {
		left: 50%;
	}
}

@media screen and (max-width: 576px) {
	.homes2-title {
		width: 80%;
	}

	.carousel-homes2.owl-carousel .owl-nav button.owl-prev {
		left: 40%;
	}

	.carousel-homes2.owl-carousel .owl-nav button.owl-next {
		left: 50%;
	}
}

@media screen and (max-width: 450px) {
	.carousel-homes2.owl-carousel .owl-nav button.owl-prev {
		left: 38%;
	}

	.carousel-homes2.owl-carousel .owl-nav button.owl-next {
		left: 50%;
	}
}


/* HOME: S3 */

.home-s3 {
	background-image: url('assets/images/bg/home-s3.png');
	background-position: top right;
	background-repeat: no-repeat;
	background-size: 35vw;
}

.homes3-container {
	display: flex;
	align-items: flex-start;
}

.homes3-img-container {
	width: 40%;
	display: inline-block;
}

.homes3-img-container img {
	width: 100%;
	position: relative;
	top: 20px;
}

.homes3-info-container {
	width: 50%;
	padding: 10px 40px;
	margin-left: auto;
}

.homes3-title {
	font-family: 'Moderat B';
	font-size: 40px;
	color: #205080;
	line-height: 130%;
	margin-bottom: 60px;
}

.homes3-flag-container {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: flex-start;
	column-gap: 30px;
	row-gap: 40px;
}

.homes3-flag {
	width: 25%;
	display: flex;
	align-items: center;
	column-gap: 15px;
}

.homes3-flag p {
	color: #205080;
	margin-bottom: 0;
}

.homes3-img.mobile {
	display: none;
}

@media screen and (max-width: 1200px) {
	.homes3-container {
		align-items: center;
	}

	.homes3-img {
		width: 100%;
	}

	.homes3-info-container {
		width: 60%;
		margin-left: 0;
	}
}

@media screen and (max-width: 992px) {
	.homes3-img {
		width: 90%;
	}

	.homes3-info-container {
		padding: 120px 0;
	}

	.homes3-flag-container {
		column-gap: 15px;
	}
}

@media screen and (max-width: 768px) {
	.home-s3 {
		background-position: bottom center;
		background-size: 50vw;
	}

	.homes3-title {
		font-size: 28px;
		margin-bottom: 30px;
	}

	.homes3-img.desktop {
		display: none;
	}

	.homes3-img.mobile {
		display: block;
		width: 30%;
		margin: auto;
		margin-top: 40px;
	}

	.homes3-img-container {
		width: 100%;
		text-align: center;
	}

	.homes3-container {
		margin-top: -10vh;
		padding-bottom: 5vh;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
	}

	.homes3-info-container {
		padding: 10px 0 120px 0;
		width: 100%;
		margin: auto;
		text-align: center;
	}

	.homes3-flag {
		justify-content: center;
	}

	.homes3-flag-container {
		row-gap: 15px;
		justify-content: center;
	}
}

@media screen and (max-width: 576px) {
	.home-s3 {
		background: #ffffff;
	}

	.homes3-flag {
		flex-wrap: wrap;
		column-gap: 100%;
		row-gap: 10px;
		align-items: center;
		justify-content: center;
	}
}


/* HOME: S4 */

.home-s4 {
	background-image: url('assets/images/bg/home-s4.jpg');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

@media screen and (min-width: 1400px) {
	.home-s4 {
		background-size: auto 1028px;
		background-position: center 40px;
	}
}

.home-s4 .container-1280 {
	padding: 3vh 0;
}

.bg-homes4 {
	width: 100%;
	position: absolute;
	top: -5vh;
	right: 0;
	z-index: -1;
}

.homes4-title {
	font-family: 'Moderat B';
	font-size: 40px;
	text-align: center;
	color: #ffffff;
	line-height: 130%;
	margin-bottom: 25px;
	margin-top: 45px;
}

.homes4-card-container {
	display: flex;
	column-gap: 30px;
	justify-content: center;
	padding-bottom: 90px;
}

.homes4-card {
	display: flex;
	flex-direction: column;
	row-gap: 30px;
	width: 28%;
	padding: 60px 40px;
	text-align: center;
	border-radius: 20px;
}

.homes4-card1 {
	background: transparent linear-gradient(180deg, #8d30b4 0%, #5C4E3600 100%) 0% 0% no-repeat padding-box;
}

.homes4-card1:hover {
	background: transparent linear-gradient(180deg, #8d30b4 0%, #5c0283 100%) 0% 0% no-repeat padding-box;
}

.homes4-card2 {
	background: transparent linear-gradient(180deg, #8C9BB3 0%, #464E5A00 100%) 0% 0% no-repeat padding-box;
}

.homes4-card2:hover {
	background: transparent linear-gradient(180deg, #8C9BB3 0%, #464E5A 100%) 0% 0% no-repeat padding-box;
}

.homes4-card3 {
	background: transparent linear-gradient(180deg, #4CBD43 0%, #265F2200 100%) 0% 0% no-repeat padding-box;
}

.homes4-card3:hover {
	background: transparent linear-gradient(180deg, #4CBD43 0%, #265F22 100%) 0% 0% no-repeat padding-box;
}

.homes4-card-title {
	font-family: 'Moderat B';
	font-size: 36px;
	color: white;
	margin-bottom: 0;
}

.homes4-card-text {
	color: white;
	font-size: 16px;
	line-height: 130%;
	width: 90%;
	margin: auto;
	height: 170px;
}

.homes4-card-btn {
	width: 50%;
	margin: auto;
	background-color: white;
	color: #0b2959;
	font-family: 'Open Sans SB';
	border-radius: 20px;
	padding: 10px 30px;
	opacity: .6;
}

.homes4-card-btn:hover {
	font-family: 'Open Sans SB';
	color: #0b2959;
}

.homes4-card:hover .homes4-card-btn {
	opacity: 1;
}

.carousel-homes4 {
	display: none !important;
}

@media screen and (max-width: 1400px) {
	.bg-homes4 {
		top: 0;
	}
}

@media screen and (max-width: 1300px) {
	.homes4-card-btn {
		width: auto;
	}

	.bg-homes4 {
		width: auto;
		right: 0;
	}
}

@media screen and (max-width: 1200px) {
	.homes4-card-container {
		padding-bottom: 60px;
	}

	.homes4-title {
		margin-top: 30px;
		margin-bottom: 30px;
	}
}

@media screen and (max-width: 992px) {
	.bg-homes4 {
		display: none;
	}
}

@media screen and (max-width: 768px) {
	.homes4-card-text {
		min-height: 84px;
	}

	.home-s4 {
		background-size: auto;
		margin-bottom: 50px;
	}

	.homes4-title {
		font-size: 28px;
		width: 60%;
		margin: auto;
		margin-bottom: 50px;
		text-align: center;
	}

	.homes4-card-container {
		column-gap: 15px;
	}

	.homes4-card-title {
		font-size: 28px;
	}

	.homes4-card {
		width: 90%;
		margin: auto;
		padding: 40px;
	}

	.homes4-card-container {
		display: none;
	}

	.carousel-homes4 {
		display: block !important;
		padding-bottom: 100px;
	}

	.carousel-homes4.owl-carousel .owl-nav {
		margin: auto;
		text-align: center;
	}

	.carousel-homes4.owl-carousel .owl-nav button {
		color: rgba(255, 255, 255, .7);
		font-size: 40px;
		margin-right: 10px;
	}
}

@media screen and (max-width: 576px) {
	.homes4-card-text {
		min-height: 104px;
	}
}

@media screen and (max-width: 420px) {
	.homes4-card-text {
		min-height: 146px;
	}
}

@media screen and (max-width: 340px) {
	.homes4-card-text {
		min-height: 165px;
	}
}

@media screen and (max-width: 330px) {
	.homes4-card-text {
		min-height: 187px;
	}
}


/* HOME: S5 */

.home-s5 {
	background-image: url('assets/images/bg/form.png');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: bottom;
}

.form {
	width: 50%;
	padding-top: 20%;
	padding-bottom: 10%;
}

.form-title {
	font-family: 'Moderat B';
	font-size: 40px;
	color: #205080;
	line-height: 120%;
	margin-bottom: 0;
}

.form-text {
	margin-top: 10px;
	font-size: 16px;
	color: #205080;
	line-height: 130%;
}

.form-logo {
	margin-left: 50px;
	margin-top: 35px;
}

.form-data-container {
	margin-top: 10%;
}

.form-data-title {
	font-family: 'Moderat B';
	font-size: 40px;
	color: #205080;
	line-height: 130%;
}

.form-data-container label {
	width: 100%;
	color: #205080;
	margin-top: 20px;
}

.form-data-container input,
.form-data-container select,
.form-data-container textarea {
	color: #0b2959;
	display: block;
	outline: none;
	border: none;
	border-radius: 20px;
	width: 100%;
	box-shadow: 0px 3px 6px #00000029;
	padding: 10px 15px;
	margin-top: 10px;
}

#enterdigest {
	max-width: 200px;
}

.nice-select {
	display: block;
	outline: none;
	border: none;
	border-radius: 20px;
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
	width: 100%;
	box-shadow: 0px 3px 6px #00000029;
	margin-top: 10px;
	min-height: 44px;
}

.nice-select.open {
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
	box-shadow: none;
}

.nice-select:focus {
	box-shadow: 0px 3px 6px #00000029;
}

.nice-select:hover {
	color: #0b2959;
}

.nice-select .option:hover,
.nice-select .option.focus,
.nice-select .option.selected.focus {
	background-color: transparent;
	font-weight: bold;
}

.nice-select.wide .list {
	border-radius: 0px;
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
	box-shadow: 0px 3px 6px #00000029;
	margin-top: 0;
}

.nice-select .option {
	color: #0b2959;
	font-family: 'Open Sans';
}

.nice-select::after {
	border-bottom: 2px solid #0b2959;
	border-right: 2px solid #0b2959;
	width: 7px;
	height: 7px;
}

.form-2col {
	display: flex;
	justify-content: space-between;
	column-gap: 20px;
}

.btn-form-container {
	margin: auto;
	text-align: center;
	margin-top: 10%;
}

@media screen and (max-width: 992px) {
	.form {
		width: 70%;
	}
}

@media screen and (max-width: 768px) {
	.form-title {
		font-size: 28px;
	}

	.form-data-title {
		font-size: 28px;
		margin-bottom: 0;
	}

	.form {
		width: 100%;
	}

	.form-2col {
		flex-wrap: wrap;
	}

	.form-logo {
		margin: auto;
		text-align: center;
	}

	.form-logo img {
		width: 80%;
	}

	.home-s5 {
		background-size: 150vh;
		background-position: bottom right;
	}
}


/* INDIVIDUALS: S1 */

.individuals-s1 {
	background-image: url('assets/images/bg/individuals-s1.jpg');
	background-position: bottom;
}

.individuals-s1-container {
	padding: 20vh 0 30vh 0;
}

.individuals1-title {
	margin-bottom: 80px;
}

.individuals1-subtitle {
	font-family: 'Open Sans B';
	margin-bottom: 80px;
}

.individuals1-text {
	width: 60%;
	margin: auto;
	text-align: center;
	font-size: 40px;
	line-height: 120%;
	font-family: 'Moderat B';
}

.individuals1-icon {
	background: url('assets/images/bg/individuals1-icon.svg');
	background-size: cover;
	background-repeat: no-repeat;
	padding: 30px;
	row-gap: 20px;
}

.individuals1-icon p {
	width: 100%;
	font-family: 'Open Sans SB';
}

.individuals-icon-num {
	width: 80px;
	height: 80px;
	border: 3px solid #fff;
	border-radius: 50%;
	background: rgba(76, 189, 67, 1);
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
}

.individuals-icon-num {
	font-family: 'Open Sans B';
	font-size: 40px;
	margin-bottom: 0;
}

.individuals1-btn-container {
	text-align: center;
	margin-top: 40px;
	margin-bottom: 10%;
}

.btn-verde-individuals {
	width: auto;
	background-color: #fff;
	border-radius: 20px;
	color: #0b2959;
	padding: 10px 25px;
	font-family: 'Open Sans SB';
}

.btn-verde-individuals:hover {
	background-color: #4CBD43;
	color: #ffffff;
}

@media screen and (max-width: 768px) {
	.individuals1-title {
		margin-bottom: 60px;
	}

	.individuals1-btn-container {
		margin-top: 20px;
		margin-bottom: 15%;
	}

	.individuals1-subtitle {
		margin-bottom: 60px;
	}
}


/* INDIVIDUALS: S2 */

.individuals-s2 {
	overflow: hidden;
}

@media screen and (min-width: 1200px) {
	.individuals-s2 {
		margin-top: -10vh;
	}
}

.individuals2-title-container {
	width: 60%;
	margin-bottom: 10%;
}

.individuals2-title {
	font-family: 'Moderat B';
	color: #205080;
	font-size: 40px;
	line-height: 120%;
	margin-bottom: 25px;
}

.individuals2-subtitle {
	font-family: 'Open Sans';
	color: #205080;
	font-size: 20px;
	line-height: 120%;
	margin-bottom: 25px;
}

.individuals2-item {
	display: flex;
	flex-direction: column;
	row-gap: 15px;
	align-items: center;
	justify-content: center;
	margin: 0 10px;
}

.individuals2-item img {
	width: 60px !important;
}

.individuals2-item p {
	font-family: 'Moderat B';
	color: #205080;
	font-size: 20px;
	line-height: 120%;
	margin-bottom: 25px;
}

.owl-carousel.carousel-individuals2 .owl-nav.disabled {
	display: block;
}

.carousel-individuals2 .owl-stage-outer {
	overflow: visible;
}

.carousel-individuals2.owl-carousel .owl-nav button {
	position: absolute;
	top: -100px;
	color: #8C9BB3;
	background-color: #d7dce4;
	height: 50px;
	width: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
}

.carousel-individuals2.owl-carousel .owl-nav button span {
	font-size: 45px;
	margin-bottom: 8px;
}

.carousel-individuals2.owl-carousel .owl-nav button.owl-prev {
	left: 45%;
}

.carousel-individuals2.owl-carousel .owl-nav button.owl-next {
	left: 50%;
}

.carousel-individuals2.owl-carousel .owl-nav button:hover {
	background-color: #205080;
	color: white;
}

.individuals2-btn-container {
	text-align: center;
	margin-top: 40px;
	margin-bottom: 10%;
}

.btn-blue-individuals {
	width: auto;
	background-color: #0b2959;
	border-radius: 20px;
	color: #fff;
	padding: 12px 30px;
}

.btn-blue-individuals:hover {
	background-color: #4CBD43;
	color: #ffffff;
}

.individuals-s3 {
	position: relative;
	background: none;
	margin: 50px 0px;
}

.individuals-s3 .container-1280 {
	overflow: hidden !important;
}

.individuals3-elipse-container {
	position: absolute;
	top: -40%;
	right: 0;
	z-index: -1;
}

.elipse-individuals3 {
	width: 70%;
	margin-left: 25%;
}

@media screen and (max-width: 1200px) {
	.carousel-individuals2.owl-carousel .owl-nav button {
		top: -90px
	}

	.carousel-individuals2.owl-carousel .owl-nav button.owl-next {
		left: 52%;
	}
}

@media screen and (max-width: 992px) {
	.individuals1-text {
		width: 80%;
	}

	.individuals2-title-container {
		width: 70%;
		margin-bottom: 15%;
	}

	.carousel-individuals2.owl-carousel .owl-nav button {
		top: -70px;
	}

	.carousel-individuals2.owl-carousel .owl-nav button.owl-prev {
		left: 40%;
	}

	.home-s3.individuals-s3 {
		overflow-x: clip;
	}

	.elipse-individuals3 {
		margin-left: 40%;
	}

	.individuals3-elipse-container {
		top: 10%;
	}
}

@media screen and (max-width: 768px) {
	.individuals-s3 .homes3-container {
		margin-top: 0;
	}

	.individuals-s1 {
		background-position: right;
	}

	.individuals-s1-container {
		padding: 20vh 0;
	}

	.individuals2-title-container {
		width: 100%;
		margin-bottom: 25%;
	}

	.individuals2-title {
		font-size: 28px;
	}

	.individuals2-subtitle {
		font-size: 18px;
	}

	.individuals1-text {
		width: 100%;
		font-size: 28px;
	}

	.individuals3-info-container {
		padding: 10px 0 40px 0;
	}

	.elipse-individuals3 {
		margin-top: 20%;
	}

	.individuals3-elipse-container {
		top: -20%;
	}
}

@media screen and (max-width: 576px) {
	.carousel-individuals2.owl-carousel .owl-nav button.owl-prev {
		left: 35%;
	}

	.elipse-individuals3 {
		margin-top: 50%;
	}
}

@media screen and (max-width: 450px) {
	.individuals2-title-container {
		margin-bottom: 30%;
	}
}

@media screen and (max-width: 390px) {
	.individuals2-title-container {
		margin-bottom: 35%;
	}

	.carousel-homes2.owl-carousel .owl-nav button.owl-prev {
		left: 36%;
	}
}


/* INDIVIDUALS: S5  */

.individuals-s5 {
	background-image: url('assets/images/bg/individuals-s5.jpg');
	background-position: top center;
}

@media screen and (min-width: 1700px) {
	.individuals-s5 {
		background-size: 100% 100%;
	}
}

.individuals-s5 .container-1280 {
	padding: 20vh 0;
}

.individuals5-corte-top {
	position: absolute;
	top: -2px;
}

.individuals5-title-container {
	width: 70%;
	margin: auto;
}

.individuals-s5-title {
	margin-bottom: 20px;
}

.individuals-s5-subtitle {
	color: #fff;
	text-align: center;
	line-height: 130%;
	font-size: 18px;
	margin-bottom: 30px;
}

@media screen and (min-width: 769px) {
	.individuals-form {
		margin-top: -20vh;
	}
}

.form.no-p-top {
	padding-top: 0;
}

.individuals5-card-container {
	margin-bottom: 8%;
}

.individuals5-card {
	text-align: left;
	padding: 40px;
}

.individuals5-title {
	font-size: 24px;
	line-height: 120%;
	text-align: center;
}

.individuals5-text {
	font-size: 16px;
	line-height: 140%;
	height: auto;
}

.homes4-card-text.individuals5-text {
	min-height: auto;
}

.individuals5-text strong {
	font-family: 'Open Sans B';
}

@media screen and (max-width: 992px) {
	.individuals-s5 .container-1280 {
		padding: 20vh 0;
	}

	.individuals5-card {
		width: 40%;
	}

	.individuals5-title-container {
		width: 85%;
	}
}

@media screen and (max-width: 768px) {
	.home-s1.individuals-s5 {
		margin-bottom: 0;
		background-position: right;
		background-repeat: no-repeat;
	}

	.individuals-s5 .container-1280 {
		padding: 0;
		padding-bottom: 10vh;
	}

	.individuals5-title-container {
		width: 100%;
	}

	.individuals-s5-title {
		width: 100%;
		padding-top: 20%;
	}

	.individuals5-card {
		width: 70%;
	}

	.individuals-s5-subtitle {
		font-size: 16px;
	}

	.individuals-s5 .owl-carousel .owl-dots {
		margin-bottom: 10%;
	}
}

@media screen and (max-width: 576px) {
	.individuals5-card {
		width: 90%;
		padding: 30px 20px;
	}

	.individuals-s5-subtitle {
		text-align: left;
		font-size: 14px;
	}

	.individuals-s5 .container-1280 {
		padding: 10vh 0;
	}

	.individuals-s5-title {
		font-size: 24px;
	}

	.individuals-s5-subtitle {
		font-size: 13px;
	}

	.individuals-s5-title {
		padding-top: 15%;
	}
}


/* ADVANCE: S1 */

.advance-s1 {
	background-image: url('assets/images/bg/advance-s1.jpg');
}

.advances1-subtitle {
	font-family: 'Open Sans B';
	font-size: 24px;
	margin-bottom: 10px;
	color: #ffffff;
	line-height: 120%;
}

.advances1-subtitle-text {
	width: 40%;
	line-height: 120%;
	margin: auto;
	text-align: center;
	font-size: 18px;
	margin-bottom: 80px;
	color: #ffffff;
}

.advances1-icon-container {
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	width: 70%;
	margin: 30px auto 100px auto;
	row-gap: 20px;
}

.advances1-icon {
	background: url('assets/images/bg/advances1-icon.svg');
	background-size: cover;
	background-repeat: no-repeat;
	padding: 30px 15px;
	align-items: center;
	justify-content: center;
}

.advances1-icon p {
	font-family: 'Open Sans SB';
}

@media screen and (max-width: 1200px) {
	.advances1-icon-container {
		width: 90%;
	}

	.advances1-subtitle-text {
		width: 50%;
	}
}

@media screen and (max-width: 992px) {
	.advances1-subtitle-text {
		width: 70%;
	}

	.advances1-icon img {
		height: 65px;
	}

	.individuals-s1-container {
		padding: 15vh 0 10vh 0;
	}

	.advance-s1 {
		background-position: inherit;
	}
}

@media screen and (max-width: 768px) {
	.advances1-icon {
		width: 200px;
		height: 200px;
	}

	.advances1-icon-container {
		width: 100%;
		column-gap: 20px;
	}
}

@media screen and (max-width: 576px) {
	.advances1-icon img {
		width: 50px;
		height: auto;
	}

	.advances1-icon {
		width: 170px;
		height: 170px;
		row-gap: 15px;
		padding: 20px;
	}

	.advances1-icon p {
		font-size: 15px;
		width: 100%;
	}

	.advances1-subtitle-text {
		width: 90%;
	}
}

@media screen and (max-width: 420px) {
	.advances1-icon {
		width: 150px;
		height: 150px;
		row-gap: 7px;
	}

	.advances1-icon p {
		font-size: 14px;
		width: 100%;
	}

	.advances1-icon-container {
		column-gap: 10px;
	}
}


/* ADVANCE: S2 */

.btn-blue-advance {
	width: auto;
	background-color: #0b2959;
	border-radius: 20px;
	color: #fff;
	padding: 12px 30px;
}

.btn-blue-advance:hover {
	background-color: #8C9BB3;
	color: #ffffff;
}

.advances2-btn-container {
	text-align: center;
	margin-top: 20px;
	margin-bottom: 40px;
}


/* ADVANCE: S3 */

.advance-s3 {
	min-height: 680px;
	background: none;
}

.advances3-img {
	position: absolute;
	width: 100%;
	max-width: 45vw;
	z-index: -1;
}

.trama-advance-container {
	margin-bottom: 20%;
}

.trama-advance {
	position: absolute;
	top: -30vh;
	right: 0;
	z-index: -2;
	opacity: .5;
}

.advances3-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.advances3-icon-title {
	font-family: 'Moderat B';
	font-size: 38px;
	color: white;
	line-height: 120%;
	text-align: center;
}

.advances3-icon-text {
	color: white;
	font-size: 20px;
	text-align: center;
	line-height: 130%;
	width: 80%;
}

.advances3-icon {
	width: 100px;
}

.fondo-advance-mobile {
	display: none;
}

@media screen and (max-width: 1300px) {
	.advances3-icon-container {
		padding: 40px 40px 120px 40px;
	}

	.advances3-icon-text {
		width: 70%;
	}
}

@media screen and (max-width: 1200px) {
	.homes3-container {
		column-gap: 20px;
	}

	.advances3-icon-title {
		font-size: 36px;
	}

	.advance-s3-mobile-container {
		width: 60%;
	}

	.advances3-icon-text {
		width: 70%;
		font-size: 20px;
	}

	.trama-advance {
		top: -20vh;
	}
}

@media screen and (max-width: 992px) {
	.advances3-info-container .homes3-flag img {
		width: 40px;
	}

	.advances3-icon-container {
		justify-content: flex-start;
		padding: 40px;
		min-height: 520px;
	}

	.advances3-icon {
		width: 80px;
	}

	.advances3-icon-title {
		font-size: 28px;
	}

	.advances3-icon-text {
		font-size: 18px;
		line-height: 120%;
	}
}

@media screen and (max-width: 768px) {
	.advances3-info-container {
		width: 100%;
	}

	.advance-s3 {
		overflow: hidden;
	}

	.advances3-container {
		padding-top: 5vh;
		min-height: auto;
	}

	.advance-s3-mobile-container {
		position: relative;
		width: 100%;
		min-height: 750px;
	}

	.trama-advance {
		top: -10vh;
	}

	.fondo-advance-mobile {
		display: block;
		position: absolute;
		top: 0;
		left: -50px;
		z-index: -1;
		width: 100%;
	}

	.advances3-icon-container {
		background: none;
		position: absolute;
		top: 20%;
		left: 0;
		z-index: 0;
		width: 90%;
	}
}

@media screen and (max-width: 600px) {
	.advance-s3-mobile-container {
		min-height: 650px;
	}

	.advances3-icon-container {
		top: 10%;
		left: 5%;
		width: 80%;
	}
}

@media screen and (max-width: 576px) {
	.advance-s3-mobile-container {
		min-height: 600px;
	}

	.advances3-icon-container {
		padding: 0;
		top: 15%;
	}
}

@media screen and (max-width: 500px) {
	.fondo-advance-mobile {
		width: 120%;
	}

	.advance-s3-mobile-container {
		min-height: 550px;
	}

	.advances3-icon-container {
		padding: 50px 0;
		top: 10%;
		width: 90%;
	}

	.trama-advance {
		top: -15vh;
	}
}

@media screen and (max-width: 420px) {
	.advance-s3-mobile-container {
		min-height: 500px;
	}

	.advances3-icon-container {
		padding: 0;
		top: 15%;
	}

	.advances3-icon-title {
		font-size: 24px;
	}

	.advances3-icon-text {
		font-size: 16px;
		width: 100%;
	}
}

@media screen and (max-width: 380px) {
	.advance-s3-mobile-container {
		min-height: 400px;
	}

	.advances3-icon-container {
		row-gap: 10px;
		top: 10%;
	}
}

.advance-s3-icon-container {
	width: 50%;
	position: relative;
}

.fondo-advance {
	width: 120%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
}

.texto-advance {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding: 45% 10% 25% 25%;
}

.advance-s3-icon-container-mobile {
	display: none;
}

@media screen and (max-width: 1300px) {
	.fondo-advance {
		left: -10%;
		width: 140%;
	}

	.advances3-icon-title {
		margin-top: 15px;
	}

	.advances3-icon-text {
		margin-bottom: 0;
		width: 80%;
	}
}

@media screen and (max-width: 1150px) {
	.texto-advance {
		padding-right: 0;
	}

	.advances3-icon-title {
		font-size: 30px;
	}

	.advances3-icon-text {
		font-size: 18px;
	}
}

@media screen and (max-width: 992px) {
	.advance-s3-icon-container {
		width: 55%;
	}

	.advances3-icon-text {
		width: 100%;
	}

	.advances3-info-container {
		padding-left: 40px;
	}
}

@media screen and (max-width: 768px) {
	.home-s3.advance-s3 .container-1280 {
		overflow-x: clip;
	}

	.advance-s3-icon-container {
		display: none;
	}

	.advance-s3-icon-container-mobile {
		display: block;
		background-image: url('assets/images/img/advance-s3.png');
		background-size: 100vw;
		background-position: top center;
		background-repeat: no-repeat;
	}

	.advance-s3-icon-mobile-info {
		width: 90vw;
		padding: 40% 25% 25% 25%;
	}

	.advances3-info-container {
		padding-right: 40px;
		padding-top: 40px;
		padding-bottom: 40px;
	}
}

@media screen and (max-width: 536px) {
	.advance-s3-icon-mobile-info {
		width: 100vw;
		padding: 45% 20% 20% 18%;
	}

	.advance-s3-icon-container-mobile {
		background-size: 120vw;
		margin-top: 40px;
	}
}

@media screen and (max-width: 450px) {
	.advance-s3-icon-mobile-info {
		padding: 40% 18% 25% 20%;
	}

	.advances3-icon-text {
		font-size: 15px;
	}

	.advances3-icon-title {
		margin-top: 0;
		font-size: 28px;
	}
}

@media screen and (max-width: 360px) {
	.advance-s3-icon-mobile-info {
		padding: 40% 15% 25% 15%;
	}

	.advances3-icon-title {
		margin-top: 0;
		font-size: 24px;
	}

	.advances3-icon-text {
		width: 90%;
		margin: auto;
	}
}

@media screen and (max-width: 340px) {
	.advances3-icon-title {
		margin-bottom: 5px;
	}

	.advance-s3-icon-mobile-info {
		padding: 40% 12% 25% 15%;
	}

	.advances3-icon-text {
		font-size: 14px;
	}
}


/* ADVANCE: S4 */

.advances4-mariposa-container {
	text-align: center;
	margin-bottom: 30px;
}

.advances4-mariposa-desktop {
	width: 300px;
	margin: auto;
}

.advances4-mariposa-mobile {
	display: none;
}

@media screen and (max-width: 768px) {
	.advances4-mariposa-desktop {
		width: 250px;
	}
}

@media screen and (max-width: 576px) {
	.advances4-mariposa-mobile {
		display: inline-block;
		width: 100px;
	}

	.advances4-mariposa-desktop {
		display: none;
		margin: auto;
	}
}


/* CORPORATE: S1 */

.corporate-s1 {
	width: 100%;
	height: 100%;
	background-image: url('assets/images/bg/corporate-s1.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: top right;
}

.corporates1-container {
	padding-top: 20vh;
}

@media screen and (min-width: 1800px) {
	.corporate-s1 {
		background-size: 100% 100%;
		background-position: bottom;
	}

	.corporates1-container {
		padding-top: 15vh;
	}
}

.corporates1-icon-container {
	justify-content: center;
	align-items: center;
	margin: auto;
	flex-wrap: wrap;
	width: 70%;
	padding: 30px;
	padding-top: 40px;
	row-gap: 30px;
}

.corporates1-icon {
	background-image: url('assets/images/bg/corporates1-icon.svg');
	row-gap: 25px;
	padding: 35px;
}

.corporates1-icon p {
	width: 100%;
}

.corporates1-icon img {
	width: 75px;
}

.corporates1-btn-container {
	padding-top: 40px;
	text-align: center;
	margin: auto;
	padding-bottom: 20%;
}

.btn-white-corporate {
	width: auto;
	background-color: #fff;
	border-radius: 20px;
	color: #0b2959;
	padding: 12px 30px;
}

.btn-white-corporate:hover {
	background-color: #8D30B4;
	color: #ffffff;
}

@media screen and (max-width: 1200px) {
	.corporates1-icon-container {
		width: 85%;
	}
}

@media screen and (max-width: 768px) {
	.homes1-icon-container.corporates1-icon-container {
		column-gap: 20px;
	}

	.corporates1-icon-container {
		width: 100%;
	}

	.corporates1-icon {
		width: 200px;
		height: 200px;
		row-gap: 15px;
	}
}

@media screen and (max-width: 576px) {
	.homes1-icon-container.corporates1-icon-container {
		column-gap: 20px;
	}

	.corporates1-icon {
		width: 170px;
		height: 170px;
		row-gap: 15px;
		padding: 20px;
	}

	.corporates1-icon img {
		width: 50px;
	}

	.corporates1-icon p {
		font-size: 15px;
		width: 90%;
	}
}


/* CORPORATE S2 */

.btn-blue-corporate {
	width: auto;
	background-color: #0b2959;
	border-radius: 20px;
	color: #fff;
	padding: 12px 30px;
}

.btn-blue-corporate:hover,
.btn-blue-corporate:active,
.btn-blue-corporate:focus {
	background-color: #8D30B4;
	color: #ffffff;
}

@media screen and (max-width: 768px) {
	.corporate-s3 .homes3-container {
		margin-top: 0;
	}
}


/* CORPORATE S4  */

.corporate-s4 {
	padding-bottom: 100px;
}

.corporates4-title {
	width: 50%;
	text-align: center;
	margin: auto;
	margin-bottom: 60px;
}

.corporates4-item-container {
	width: 22vw;
	height: 200px;
	height: auto;
}

.corporates4-item-img img {
	width: 100px;
	height: auto;
}

.corporates4-item-title {
	font-family: 'Open Sans B';
	color: #0b2959 !important;
	font-size: 21px !important;
	text-align: center;
	margin-bottom: 30px;
	min-height: 59px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.corporates4-item-container p {
	color: #205080;
	font-size: 14px;
	margin-bottom: 30px;
}

.corporates4-item-container button {
	padding: 10px 25px;
	margin: auto;
}

.corporates4-card-container {
	display: flex;
	margin: auto;
	column-gap: 30px;
	align-items: flex-start;
	justify-content: center;
}

@media screen and (max-width:1300px) {
	.corporates4-item-container {
		width: 25vw;
	}
}

@media screen and (max-width: 1100px) {
	.corporates4-title {
		width: 70%;
		margin-bottom: 100px;
	}

	.corporates4-item-container {
		width: 30vw;
		min-height: 285px;
	}
}

@media screen and (max-width: 992px) {
	.corporates4-title {
		width: 85%;
		margin-bottom: 100px;
	}

	.homes2-item-img {
		left: 32%;
	}
}

@media screen and (max-width: 768px) {
	.corporates4-card-container {
		flex-wrap: wrap;
		row-gap: 60px;
	}

	.corporates4-item-container {
		width: 90%;
		margin: auto;
	}

	.homes2-item-img {
		left: 42%;
	}
}

@media screen and (max-width: 576px) {
	.homes2-item-img {
		left: 38%;
	}
}

@media screen and (max-width: 420px) {
	.homes2-item-img {
		left: 35%;
	}
}


/* CORPORATE S5 */

.corporate-s5 {
	background-image: url('assets/images/bg/corporate-s5.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: top center;
	/* min-height: 1500px; */
}

.corporates5-icon-container {
	width: 90%;
	margin: auto;
	padding-top: 15%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: flex-start;
	column-gap: 7%;
	row-gap: 40px;
}

.corporates5-icon {
	display: flex;
	justify-content: center;
	align-items: center;
	row-gap: 10px;
	flex-direction: column;
}

.corporates5-icon img {
	width: 70px;
}

.corporates5-icon p {
	font-family: 'Open Sans B';
	color: white;
	font-size: 15px;
	line-height: 22px;
	width: 130px;
	text-align: center;
	margin-bottom: 0;
}

.corporates5-card-container {
	padding: 20vh 0;
}

.corporates5-card {
	background: transparent linear-gradient(180deg, #8D30B4 0%, #8D30B400 100%) 0% 0% no-repeat padding-box;
	padding: 50px;
	align-items: flex-start;
	justify-content: center;
}

.corporates5-card:hover {
	background: rgba(141, 48, 180, .8);
}

.corporates5-card img {
	width: 100px;
	margin: auto;
}

.owl-carousel.owl-theme.carousel-corporates5.corporates5-icon-mobile {
	display: none;
}

.corporate-mariposa {
	display: none;
}

@media screen and (min-width: 1880px) {
	.corporate-s5 {
		background-size: 100% 100%;
	}
}

@media screen and (max-width: 1115px) {
	.corporates5-icon-container {
		width: 100%;
		column-gap: 5%;
	}

	.corporates5-card {
		padding: 40px;
	}
}

@media screen and (max-width: 992px) {
	.corporates5-icon-container {
		padding-top: 20%;
		column-gap: 7px;
	}

	.corporates5-card {
		padding: 25px;
		width: 30%;
	}

	.corporates5-card img {
		width: 90px;
	}

	.corporates5-card-container {
		padding: 15vh 0;
	}
}

@media screen and (max-width: 768px) {
	.corporates5-icon-container.corporates5-icon-desktop {
		display: none;
	}

	.owl-carousel.owl-theme.carousel-corporates5.corporates5-icon-mobile {
		display: block;
		padding-top: 25%;
	}

	.carousel-corporates5.owl-carousel .owl-dots {
		display: none;
	}

	.carousel-corporates5.owl-carousel .owl-nav button {
		position: absolute;
		top: 70px;
		color: #0b2959;
		background-color: #fff;
		height: 40px;
		width: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
	}

	.carousel-corporates5.owl-carousel .owl-nav button span {
		color: #0b2959;
		font-size: 30px;
		margin-bottom: 3px;
		font-weight: bold;
	}

	.carousel-corporates5.owl-carousel .owl-nav button.owl-prev {
		left: 42%;
	}

	.carousel-corporates5.owl-carousel .owl-nav button.owl-next {
		left: 50%;
	}

	.carousel-corporates5.owl-carousel .owl-item img {
		width: 90px;
		margin-top: 10px;
	}

	.corporates5-card-mobile .corporates5-card {
		width: 80%;
	}

	.corporates5-card-mobile.owl-carousel .owl-item img {
		width: 90px;
	}

	.corporates5-card-mobile.owl-carousel .owl-dots {
		margin-bottom: 20%;
	}

	.corporates5-card-container {
		padding: 25vh 0 15vh 0;
	}
}

@media screen and (max-width: 640px) {
	.carousel-corporates5.owl-carousel .owl-nav button.owl-prev {
		left: 40%;
	}

	.carousel-corporates5.owl-carousel .owl-nav button.owl-next {
		left: 50%;
	}
}

@media screen and (max-width: 576px) {
	.carousel-corporates5.owl-carousel .owl-nav button {
		top: 80px;
		height: 30px;
		width: 30px;
	}

	.carousel-corporates5.owl-carousel .owl-nav button span {
		font-size: 20px;
	}

	.carousel-corporates5.owl-carousel .owl-nav button.owl-prev {
		left: 43%;
	}

	.carousel-corporates5.owl-carousel .owl-nav button.owl-next {
		left: 53%;
	}

	.carousel-corporates5.owl-carousel .owl-item img {
		width: 70px;
	}

	.corporates5-card-mobile.owl-carousel .owl-item img {
		width: 70px;
	}

	.owl-carousel.owl-theme.carousel-corporates5.corporates5-icon-mobile {
		padding-top: 30%;
	}

	.corporates5-card-container {
		padding: 20vh 0 12vh 0;
	}
}

@media screen and (max-width: 420px) {
	.carousel-corporates5.owl-carousel .owl-nav button {
		top: 70px;
	}

	.carousel-corporates5.owl-carousel .owl-nav button.owl-prev {
		left: 40%;
	}

	.owl-carousel.owl-theme.carousel-corporates5.corporates5-icon-mobile {
		padding-top: 35%;
	}

	.corporates5-icon p {
		font-size: 12px;
		line-height: 130%;
		width: 100px;
	}

	.corporates5-card-mobile.owl-carousel .owl-dots {
		margin-bottom: 20%;
	}
}

@media screen and (max-width: 400px) {
	.corporates5-card-container {
		padding-top: 23vh;
	}
}

@media screen and (max-width: 360px) {
	.owl-carousel.owl-theme.carousel-corporates5.corporates5-icon-mobile {
		padding-top: 45%;
	}

	.carousel-corporates5.owl-carousel .owl-nav button {
		top: 65px;
	}

	.carousel-corporates5.owl-carousel .owl-nav button.owl-prev {
		left: 40%;
	}

	.carousel-corporates5.owl-carousel .owl-nav button.owl-next {
		left: 52%;
	}
}


/* CORPORATE S6 */

.corporate-s6 .homes3-title {
	text-align: center;
}

.corporates6-card-container {
	display: flex;
	column-gap: 50px;
	justify-content: center;
	align-items: flex-start;
	margin-bottom: 60px;
}

.corporates6-card {
	width: 45%;
	padding: 30px;
	position: relative;
}

.corporates6-card::before {
	content: "";
	position: absolute;
	inset: 0;
	border-radius: 20px;
	padding: 3px;
	background: linear-gradient(to bottom, rgba(199, 68, 245, 1), rgba(92, 78, 54, 0));
	-webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
	-webkit-mask-composite: xor;
	mask-composite: exclude;
}

.corporates6-card-title {
	color: #8D30B4;
	font-family: 'Open Sans SB';
	font-size: 24px;
	text-align: center;
	margin-bottom: 20px;
}

.corporates6-card-img {
	width: 100%;
	padding-bottom: 15px;
	border-bottom: 2px solid #C744F5;
}

.corporates6-card-text-blanco {
	padding: 30px;
	color: #0b2959;
	text-align: center;
	margin-bottom: 0;
}

.corporates6-card-text-violeta {
	padding: 30px;
	color: #0b2959;
	text-align: center;
	margin-bottom: 0;
	background-color: rgba(89, 19, 117, .2);
}

.corporates6-card-text-blanco:last-of-type {
	border-bottom: 2px solid #C744F5;
}

@media screen and (min-width: 992px) {
	.corporate-s6 {
		margin-top: -20vh;
	}
}

@media screen and (max-width: 992px) {
	.corporate-s6 {
		margin-top: -15vh;
	}

	.corporates6-card {
		width: 50%;
	}
}

@media screen and (max-width: 768px) {
	.corporate-s6 {
		margin-top: -5vh;
	}

	.corporates6-card-container {
		flex-wrap: wrap;
		row-gap: 30px;
	}

	.corporates6-card {
		width: 90%;
		margin: auto;
		padding: 30px 15px;
	}

	.corporates6-card-text-blanco {
		padding: 20px;
	}

	.corporates6-card-text-violeta {
		padding: 20px;
	}

	.corporate-s6 {
		position: relative;
	}

	.corporate-mariposa {
		display: block;
		position: absolute;
		width: 100px;
		top: -15vh;
		left: 40%;
	}
}


/* PARTNERS S1 */

.partners-s1 {
	background-image: url('assets/images/bg/partners-s1.jpg');
	background-repeat: no-repeat;
	background-size: cover;
}

.partners-s1 .homes1-icon-container {
	padding-top: 7%;
}

@media screen and (min-width: 1200px) {
	.partners-s1 {
		background-position: bottom;
	}
}

.partners1-icon {
	background-image: url('assets/images/bg/partners1-icon.svg');
	background-repeat: no-repeat;
	background-size: cover;
	width: 270px;
	height: 270px;
	row-gap: 15px;
	padding: 35px;
}

.partners1-icon p {
	width: 95%;
	font-size: 16px;
}

@media screen and (max-width: 992px) {
	.partners1-icon {
		width: 240px;
		height: 240px;
		row-gap: 15px;
		padding: 25px;
	}

	.partners1-icon img {
		width: 85px;
	}

	.partners1-icon p {
		font-size: 14px;
		width: 75%;
	}

	.partners-s1 .homes1-icon-container {
		padding: 20px 0;
		column-gap: 5px;
	}
}

@media screen and (max-width: 768px) {
	.partners1-icon {
		width: 250px;
		height: 250px;
	}

	.partners-s1 .homes1-icon-container {
		column-gap: 10%;
	}
}

@media screen and (max-width: 576px) {
	.partners-s1 .homes1-icon-container {
		column-gap: 100%;
		padding-bottom: 15%;
	}

	.partners-s1 .homes1-container {
		padding: 15vh 0 10vh 0;
	}
}

.partners1-icon-container-mobile {
	display: none;
}

.partners2-title {
	font-family: 'Moderat B';
	font-size: 40px;
	color: #0b2959;
	line-height: 130%;
	margin-bottom: 60px;
	text-align: center;
	margin: auto;
	padding-top: 40px;
}

.partners1-item-container {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	padding: 5% 0;
}

.partners1-item {
	width: 120px;
	display: flex;
	flex-direction: column;
	row-gap: 15px;
	text-align: center;
	color: #205080;
	font-family: 'Open Sans SB';
}

.partners1-item img {
	width: 110px;
	margin: auto;
}

@media screen and (max-width: 768px) {
	.partners2-title {
		font-size: 28px;
	}

	.partners1-item-container {
		display: none;
	}

	.partners1-icon-container-mobile {
		display: block;
		padding-top: 10vh;
	}

	.partners1-icon-container-mobile .individuals2-item p {
		font-family: 'Open Sans SB';
		font-size: 14px;
		text-align: center;
	}

	.partners1-icon-container-mobile .owl-stage-outer {
		overflow: hidden;
	}

	.partners2-title {
		padding-top: 7vh;
	}

	.partners1-icon img {
		height: 75px;
	}
}

@media screen and (max-width: 576px) {
	.partners2-title {
		padding-top: 0px;
	}

	.partners1-icon-container-mobile {
		padding-bottom: 5vh;
	}
}


/* PARTNERS S3 */

.partners-s3 {
	background-image: url('assets/images/bg/partners-s3.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: bottom;
	margin-bottom: 70px;
}

@media screen and (min-width: 1800px) {
	.partners-s3 {
		background-size: 100% 100%;
	}
}

.partners3-title {
	font-family: 'Moderat B';
	color: white;
	font-size: 40px;
	text-align: center;
	padding-top: 15vh;
	line-height: 120%;
}

.partners3-card-container {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	padding-bottom: 10vh;
	padding-top: 50px;
	column-gap: 10%;
}

.partners3-mariposa-desktop {
	width: 35%;
}

.partners3-card-container .homes4-card.homes4-card2 {
	width: 33%;
}

.partners-s4 {
	background-size: 150vh;
	background-position: bottom right;
}

.partners3-mariposa-mobile {
	display: none;
}

.advance-home-safe app-asset-safeguard .home-s5 {
	background-image: none;
}

.advance-home-safe app-asset-safeguard .home-s5 .container-1280 {
	background-image: none;
	text-align: center;
	margin-top: -150px;
	margin-bottom: 30px;
}

.advance-home-safe app-asset-safeguard .home-s5 .container-1280 .form {
	margin: 0 auto;
}

@media screen and (min-width: 1600px) {
	.container-1280 {
		max-width: 1500px;
	}
}

@media screen and (max-width: 1200px) {
	.partners-s3 {
		background-position: right;
	}

	.partners3-card-container {
		padding-top: 20px;
		column-gap: 5%;
	}

	.partners3-card-container .homes4-card.homes4-card2 {
		width: 45%;
	}
}

@media screen and (max-width: 992px) {
	.partners3-title {
		padding-top: 10vh;
	}

	.partners3-card-container {
		align-items: center;
		padding-top: 0;
	}

	.advance-home-safe app-asset-safeguard .home-s5 .container-1280 {
		margin-top: 0px;
	}
}

@media screen and (max-width: 768px) {
	.partners-s3 {
		background-position: top;
	}

	.partners3-mariposa-desktop {
		display: none;
	}

	.partners3-card-container {
		padding-bottom: 15vh;
	}

	.partners3-card-container .homes4-card.homes4-card2 {
		width: 90%;
		padding: 20px;
	}

	.partners3-mariposa-mobile {
		display: block;
		width: 100px;
		padding-top: 5vh;
		margin: auto;
	}

	.partners3-title {
		padding: 20px;
	}

	.advance-home-safe app-asset-safeguard .home-s5 .container-1280 {
		margin-top: 0px;
	}
}

/* new houbi home banner */

.homes1-huobi {
	padding:50px 50px;
	border-bottom:1px dashed #fff;
	max-width:980px;
	margin:0 auto 50px;
	text-align:center;
	display:flex
}
.homes1-huobi div {
	display:inline-block
}
.homes1-huobi-left, .homes1-huobi-right {width:20%}
.homes1-huobi-center {width:60%}
.homes1-huobi img {max-width:100%}
.homes1-huobi h3, .homes1-huobi h4 {
	font-family: 'Moderat B';
    color: white;
    font-size: 48px;
}
.homes1-huobi h3 {margin-top:15px}
.homes1-huobi h4 {font-size:36px}

@media screen and (max-width: 767px) {
	.homes1-huobi-left, .homes1-huobi-right {width:15%}
	.homes1-huobi-center {width:70%}
	.homes1-huobi {padding:50px 0}
	.homes1-huobi h3, .homes1-huobi h4 {
    	font-size: 28px;
	}
	.homes1-huobi h4 {font-size:21px}
	.homes1-huobi h4 span {display:block}
}


/* new huobi modal */

.huobi-modal {
	background:#35676a;
	color:#fff;
	padding:20px 50px;
	position:fixed;
	bottom:0;
	display:flex;
	width:60%;
	text-align:center;
	left:20%;
	z-index: 9
}
.huobi-modal h3 {font-family: 'Moderat B';font-size:32px;color:#fff}
.huobi-modal p {color:#fff;font-size:16px}
.huobi-modal a {color:#fff}
.huobi-modal .close-huobi-modal {position:absolute;top:15px;right:20px;font-size:24px;padding:5px;display:inline-block;cursor:pointer;font-family: 'Moderat B';}

@media screen and (max-width: 1199px) {
	.huobi-modal {width:90%;left:5%}
}
@media screen and (max-width: 767px) {
	.huobi-modal h3 {font-size:24px;}
	.huobi-modal p {font-size:12px}	
}


/* modal migracion 15 mayo */

.migrate-modal a.w-btn {
    display: inline-block;
    background: #fff;
    padding: 10px 20px;
    color: #008CD6!important;
    font-size: 18px;
    margin: 10px 0 0;
}

.migrate-modal a {
	color:#fff;
	text-decoration:none
}

.migrate-modal a:hover {
	text-decoration:underline
}

.migrate-modal a.underline {
	text-decoration:underline
}


.migrate-modal a.underline:hover {
	text-decoration:none
}

.migrate-modal a.modal-contact {
	clear:both;
	display:inline-block;
	margin:30px;
	background:#fff;
	padding:15px 30px;
	color:#008CD6;
	font-size:1.25em
}
.migrate-modal {
    background: #008CD6;
    color: #fff;
    position: fixed;
    bottom: 0;
    display: flex;
    width: 100%;
    text-align: center;
    left: 0;
    z-index: 9;
    height: 100%;
    z-index: 9999;
	line-height:1.5em;
	top:0;
	overflow:hidden
}


.migrate-modal .content {
	margin: 40px auto 0;
	overflow-y:auto
}
.migrate-modal h4 {
	font-size:1.6em;
	margin:0 auto 30px;
	max-width:800px;
	border-bottom: 1px dashed #fff;
	padding-bottom:30px
}
.migrate-modal h3 {
	font-size:2.4em;
  margin:5px 0 15px;
  font-weight:bold
}
.migrate-modal .pic-container {
	width:200px;
	height:200px;
	border-radius:50%;
	background:#fff;
	margin:30px auto 0;
	text-align:center;
	overflow:hidden
}
.migrate-modal .pic-container img {
	width:160px;
	margin-top:20px
}
.migrate-modal .huobi {
	padding: 0 50px;
    max-width: 980px;
    margin: 0 auto 30px;
    text-align: center;
    display: flex;
}
.migrate-modal p {
	max-width: 980px;
	margin: 0 auto 50px;
}
.migrate-modal .huobi div {
	display:inline-block
}
.migrate-modal .huobi-left,
.migrate-modal .huobi-right {
	width:30%
}
.migrate-modal .huobi-center {
	width:40%
}
.migrate-modal .huobi-left img,
.migrate-modal .huobi-right img {
	max-width:100%
}
.migrate-modal .huobi-left img,
.migrate-modal .huobi-right img {
	position:relative;top:30px
}
@media screen and (max-width: 1200px) {
	.migrate-modal .pic-container {
		width:130px;
		height:130px;
		margin:40px auto 0
	}
	.migrate-modal .pic-container img {
		width:100px;
		margin-top:15px
	}	
	.migrate-modal .content {
		width:95%;
		padding:0 2.5%;
		margin:30px auto 0
	}
}
@media screen and (max-width: 767px) {
	.migrate-modal .huobi-left img
	 {
		top:0
	}	
  .migrate-modal .huobi-right img {
    top: 25px
  }
	.migrate-modal h3 {
		font-size:24px
	}
	.migrate-modal h4 {
		font-size:15px;
		top:0;
		padding:0 20px 30px
	}
	.migrate-modal p {
		font-size:15px
	}
	.migrate-modal .content {
		width:90%;
		padding:5%;
		margin:30px auto 0
	}
	.migrate-modal .huobi {
		padding:0 0 20px 0;
		float:left;
		width:100%
	}
	.migrate-modal .huobi .pill {
		float:left;
		text-align:center
	}
	.migrate-modal .huobi {
		display:block
	}
}
@media screen and (max-width: 550px) {

	.migrate-modal .pic-container {
		width:100px;
		height:100px;
		margin:30px auto 0
	}
	.migrate-modal .pic-container img {
		width:70px
	}	
}
